import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FooterComponent } from './footer/footer.component';
import { HttpLoaderComponent } from './http-loader/http-loader.component';

import { ModalVersionHistoryComponent } from './modals/modal-version-history/modal-version-history.component';
import { ModalAvisoComponent } from './modals/modal-aviso/modal-aviso.component';
import { ModalAddAddressComponent } from './modals/modal-add-address/modal-add-address.component';

import { MatMenuModule, MatProgressBarModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatInputModule, MatSlideToggleModule, MatExpansionModule, MatRadioModule, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [
    FooterComponent,
    ModalVersionHistoryComponent,
    ModalAvisoComponent,
    ModalAddAddressComponent,
    HttpLoaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatOptionModule,
    MatExpansionModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    IonicModule.forRoot()
  ],
  exports: [
    FooterComponent,
    ModalVersionHistoryComponent,
    ModalAvisoComponent,
    HttpLoaderComponent
  ]
})
export class ComponentsModule { }

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from './../../../../environments/environment'

@Component({
  selector: 'app-modal-version-history',
  templateUrl: './modal-version-history.component.html',
  styleUrls: ['./modal-version-history.component.scss'],
})
export class ModalVersionHistoryComponent implements OnInit {

  versionHistory = environment.version;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss()
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadingService } from './../../services/loading/loading.service';

@Component({
  selector: 'http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HttpLoaderComponent implements OnInit {

  // loading: boolean;
  
  selectedTip: Tip;
  tips: Tip[] = [
    {
      title: '<h3>Sistema <strong>fácil</strong>, inteligente y <strong>seguro</strong></h3>',
      text: `<p>Crol es el ERP en la nube más completo, con facturación ilimitada, inteligencia artificial y de negocios para cualquier empresa en México.</p>
             <p>Toma mejores decisiones con información en tiempo real, segura y confiable.</p>`,
      linkText: 'Para más información haz click aquí',
      link: 'https://crol.mx'
    },
    {
      title: '<h3>Punto de <strong>Venta</strong></h3>',
      text: `<p>Moderno e intuitivo, Crol POS facilita e integra las operaciones de venta de tus productos, actualizando en línea los inventarios, ventas, tesorería y contabilidad.</p>
             <p>Disponible para Retail y Restaurantes.</p>`,
      linkText: 'Para más información haz click aquí',
      link: 'https://crol.mx/pos'
    },
    {
      title: '<h3>Observa las operaciones de tu empresa <strong>donde lo desees</strong></h3>',
      text: `<p>Conoce tus finanzas en tiempo real con un solo clic. Cuánto ganaste, cuánto tienes, cuánto te deben y cuánto debes.</p>
             <p>Controla tu flujo de caja en cualquier lugar a la hora que quieras de forma segura y confiable.</p>`,
      linkText: 'Para más información haz click aquí',
      link: 'https://crol.mx'
    },
    {
      title: '<h3>Todo en la nube de <strong>Microsoft</strong></h3>',
      text: `<p>Toda la información de tu empresa en la nube líder en seguridad, accediendo desde cualquier lugar y monitoreada las 24x7x365.</p>
             <p>90% de las empresas incluidas en la lista de Fortune 500 confían su negocio a Microsoft Cloud.</p>
             <p>Más certificaciones que otro proveedor en la nube.</p>`,
      linkText: 'Fuente: Microsoft Azure',
      link: 'https://azure.microsoft.com/es-mx/'
    },
    {
      title: '<h3>Supervisa tu negocio <strong>en tiempo real</strong></h3>',
      text: `<p>Integra tus almacenes, inventarios y proyectos con tus sucursales de manera automática.</p>
             <p>Conoce en tiempo real el estado actual de tu empresa.</p>
             <p>Identifica las mermas de tu mercancía rapidamente.</p>`,
      linkText: 'Para más información haz click aquí',
      link: 'https://crol.mx'
    },
    {
      title: '<h3>Empieza a facturar gratis <strong>por siempre</strong></h3>',
      text: `<p>Crol Libre está diseñado para que lo use todo emprendedor y empresario en México sin costo alguno.</p>
             <p>Creemos que la mejor manera de transformar digitalmente a tu negocio y a México es dar la oportunidad de tener acceso a tecnologías inteligentes y fáciles de usar.</p>`,
      linkText: '¿Quieres saber más sobre Crol Libre? Haz click aquí',
      link: 'https://crol.mx/libre'
    },
    {
      title: '<h3>Tu aliado <strong>tecnológico</strong> en los <strong>negocios</strong></h3>',
      text: `<p>Crol es el ERP en la nube más completo de México. Sistema fácil, inteligente y seguro que permite controlar y administrar medianas empresas, franquicias y subsidiarias.</p>
             <p>Con Crol B2B, podrás supervisar en cualquier lugar todos los procesos administrativos, sucursales y proyectos en donde quieras que te encuentres.</p>`,
      linkText: 'Haz click aquí para ir a Crol B2B',
      link: 'https://crol.mx/b2b'
    }
  ]

  constructor(
    public loader: LoadingService
  ) {
    this.loader.isHttpLoading.subscribe((v) => {
      if (!this.loader.isLoading) {
        this.loader.isLoading = v;
      }
    });
    this.selectedTip = this.tips[Math.floor(Math.random() * this.tips.length)];
  }

  ngOnInit() {}

}

export interface Tip {
  title: string;
  text: string;
  linkText: string;
  link: string;
}
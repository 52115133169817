import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public isHttpLoading = new BehaviorSubject(false);
  isLoading = false;

  constructor() { }

  on() {
    console.log('cargando...')
    this.isLoading = true;
  }

  off() {
    if (this.isLoading) {
      console.log('quitar cargando.')
      this.isLoading = false;
    }
  }


}

import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MiscService {

  constructor(
    private router: Router,
    private zone: NgZone,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController
  ) { }

  async presentToast(message: string, duration?: number) {
    const toast = await this.toastCtrl.create({
      message,
      duration: (duration) ? duration : 2000
    });
    toast.present();
  }

  async presentSimpleAlert(header: string, subHeader: string, message: string) {
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: [{
        text: 'Aceptar',
        cssClass: 'btn btn-primary btn-pill'
      }]
    });
    alert.present();
  }

  makeId() {
    let text = "";
    let possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

}
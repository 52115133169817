// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const server = {
  // base_url: 'https://localhost'
  // base_url: 'https://siged.cfe.mx'
  // base_url: 'http://siged.cfemex.com:3000'
  // base_url: 'http://localhost:3000'
    azure: 'https://afcrolposv2.azurewebsites.net',
  //azure: 'http://localhost:7072/',
  signalr: 'https://afcrolsr.azurewebsites.net',
  azureProductivo: 'true',
  azureCode: 'UBqRCfGjE/gw7k/2mzWudji7SlOEld1OcWlec3e5Q1D65GWDrPx73g=='
}

export const environment = {
  production: true,
  url: server.azure + '/api',
  version: [
    { id: '0.1.18', action: 'FIX', description: 'Correcciones en modelo y endpoint para agregar C.P. con valor inicial 0 en domicilios.' },
    { id: '0.1.17', action: 'UPDATE', description: 'Se agregaron campos para facturación 4.0' },
    { id: '0.1.16', action: 'UPDATE', description: 'Se agregó la validación para cuando el RFC es XAXX010101000 o XEXX010101000' },
    { id: '0.1.15', action: 'FIX', description: 'Se agregó el arreglo de correos en el getCrolInvoice' },
    { id: '0.1.14', action: 'FIX', description: 'La página de empresa ya obtiene el logo correctamente' },
    { id: '0.1.13', action: 'FIX', description: 'Se corrigio la validacion cuando modificas manualmente la url directamente' },
    { id: '0.1.12', action: 'FIX', description: 'Se corrigio la validacion de num de empresa y código de fact que solo admita numeros' },
    { id: '0.1.11', action: 'FIX', description: 'Se corrigió el error despues de que das de alta al cliente no enviaba a las siguiente pagina' },
    { id: '0.1.10', action: 'UPDATE', description: 'Se realizaron algunas validaciones en la página de registro.' },
    { id: '0.1.9', action: 'FIX', description: 'Se cambió la url del timbrado a https.' },
    { id: '0.1.8', action: 'FIX', description: 'Se cambiaron los logos placeholder en todas las páginas.' },
    { id: '0.1.7', action: 'UPDATE', description: 'Cuando el RFC no existe, manda a la página de registro.' },
    { id: '0.1.6', action: 'UPDATE', description: 'Quedó preparada la página de Exito para recibir los parámetros faltantes.' },
    { id: '0.1.5', action: 'UPDATE', description: 'Se terminaron todos los módulos excepto la página de exito.' },
    { id: '0.1.4', action: 'UPDATE', description: 'Se cambió la forma en que se pasan los parámentros entre páginas.' },
    { id: '0.1.3', action: 'UPDATE', description: 'Se terminó el layout de la página de registro de empresa.' },
    { id: '0.1.2', action: 'UPDATE', description: 'Se terminó el diseño del loader.' },
    { id: '0.1.1', action: 'UPDATE', description: 'Se terminó el layout de la página para ingreso de código de facturación.' },
    { id: '0.1.0', action: 'UPDATE', description: 'Se terminó el layout de la página para selección de empresa.' },
    { id: '0.0.1', action: 'FIRST COMMIT', description: 'Se inició el proyecto.' },
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

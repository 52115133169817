import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './../api/api.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutofacturacionService {

  clientInfo: AfPayload;

  constructor(
    private _api: ApiService
  ) { }

  getInfoEmpresa(codigoEmpresa: string, rfc: string, codigoFacturacion: string) {
    const params = new HttpParams()
      .set('codigoEmpresa', codigoEmpresa)
      .set('rfc', rfc)
      .set('codigoFacturacion', codigoFacturacion);
    return this._api.get('getInformacionEmpresa', params).then((infoEmpresa: AfPayload) => {
      this.clientInfo = infoEmpresa;
      return infoEmpresa;
    });
  }

  getFormasPago(entidadId: number) {
    const params = new HttpParams()
      .set('entidadId', entidadId.toString());
    return this._api.get('getFormasPago', params, this.clientInfo.APIKey).then(res => {
      return res;
    });
  }

  getMetodosPago(entidadId: number) {
    const params = new HttpParams()
      .set('entidadId', entidadId.toString());
      console.log(params)
    return this._api.get('getMetodosPago', params, this.clientInfo.APIKey).then(res => {
      return res;
    });
  }

  getUsosCFDI(entidadId: number, tipoContacto: string) {
    const params = new HttpParams()
      .set('entidadId', entidadId.toString())
      .set('tipoContacto', tipoContacto);
    return this._api.get('getUsosCFDI', params, this.clientInfo.APIKey).then(res => {
      return res;
    });
  }

  getDomicilioFiscal(entidadId: number) {
    const params = new HttpParams()
      .set('regimenTipo', entidadId.toString());
    return this._api.get('getDomicilioFiscal', params, this.clientInfo.APIKey).then(res => {
      return res;
    });
  }
  
  getRegimenFiscalFisica(regimenTipo: number) {
    const params = new HttpParams()
      .set('regimenTipo',regimenTipo.toString());
    return this._api.get('getRegimenFiscalFisica', params, this.clientInfo.APIKey).then(res => {
      return res;
    });
  }

  getRegimenFiscalMoral(regimenTipo: number) {
    const params = new HttpParams()
      .set('regimenTipo',regimenTipo.toString());
    return this._api.get('getRegimenFiscalMoral', params, this.clientInfo.APIKey).then(res => {
      return res;
    });
  }
  getRegimenFiscal(entidadId: number, organizacionId: number) {
    const params = new HttpParams()
      .set('organizacionId', organizacionId.toString())
      .set('entidadId', entidadId.toString())
    return this._api.get('getRegimenFiscal', params, this.clientInfo.APIKey).then(res => {
      return res;
    });
  }
  async addClient(client: CrolClient, codigoEmpresa: string) {
    // Agregamos los campos extras reculeros
    if (!this.clientInfo) {
      await this.getInfoEmpresa(codigoEmpresa, '', '').then(res => {
        this.clientInfo = res;
      })
    }
    client['despachoId'] = this.clientInfo.despachoId;
    client['organizacionId'] = this.clientInfo.organizacionId;
    client.razonSocial = (client.razonSocial) ? client.razonSocial : (client.nombre + client.apellidoPaterno + client.apellidoMaterno);
    client['Telefono1'] = client.telefono1;
    client['Telefono2'] = client.telefono2;
    client['latitud'] = 0;
    client['longitud'] = 0;
    return this._api.post('clienteAlta', client, this.clientInfo.APIKey).then(result => {
      return result;
    });
  }

  async addClientAddress(organizacionId, entidadId, calle, numeroExterior, numeroInterior, colonia, codigoPostal) {
    const payload = {
      organizacionId,
      entidadId,
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      codigoPostal
    }
    return this._api.post('altaDomicilio', payload, this.clientInfo.APIKey).then(result => {
      return result;
    });
  }

  async editClientAddress(domicilioId, entidadId, organizacionId, calle, numeroExterior, numeroInterior, colonia, codigoPostal) {
    const payload = {
      domicilioId,
      entidadId,
      organizacionId,
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      codigoPostal
    }
    return this._api.post('modificaDomicilio', payload, this.clientInfo.APIKey).then(result => {
      return result;
    });
  }

  async removeClientAddress(domicilioId, entidadId, organizacionId) {
    const payload = {
      domicilioId,
      entidadId,
      organizacionId
    }
    return this._api.post('eliminaDomicilio', payload, this.clientInfo.APIKey).then(result => {
      return result;
    });
  }

  invoiceOrder(paymentPayload) {
    return this._api.post('notaDeVentaFactura', paymentPayload, this.clientInfo.APIKey).then(result => {
      return result;
    });
  }

  getCrolInvoice(transaccionId: string, organizacionId: string, correos: string[]) {
    const email = [{ "email": correos[0] }];
    const payload = {
      APIKey: "2455355f-a3de-4958-9cef-d06486d12fbd",
      esDesarrollo: (environment.production) ? false: true,
      transaccionId: transaccionId,
      tipoRespuesta: 1,
      organizacionId: organizacionId,
      correos : email,
      origenId : 7
    }
    return this._api.postTimbrado(this.clientInfo.APIKey, payload).then(result => {
      return result;
    });
  }

  getInvoiceJson(entidadId: number, transaccionId: string) {
    const params = new HttpParams()
      .set('entidadId', entidadId.toString())
      .set('transaccionId', transaccionId);
    return this._api.get('getJsonFactura', params, this.clientInfo.APIKey).then(res => {
      return res;
    });
  }
   
  deleteSale(transaccionId: string, organizacionId: string) {
    const payload = {
      paraEliminar: {
        usuarioId: 0,
        transaccionId: transaccionId,
        organizacionId: organizacionId
      },
      apiKey: this.clientInfo.APIKey
    }
    
    return this._api.post('eliminaVenta', payload, this.clientInfo.APIKey).then(result => {
      return result;
    });
  }

}

export interface AfPayload {
  APIKey: string;
  baseDatos: string;
  codigoFacturacion: string;
  despachoId: number;
  organizacionId?: number;
  nombre: string;
  logo: string;
  rfc: string;
  importe: number;
  informacionCliente: CrolClient;
}

// export interface ContactInfo {
//   email: string;
//   entidadId: number;
//   razonSocial: string;
//   rfc: string;
//   tipoContacto: number;
//   localizaciones: CrolAddress[];
// }

export interface CrolAddress {
  domicilioId: number;
  descripcion?: string;
  domicilio?: string;
  calle?: string;
  noExterior?: string;
  noInterior?: string;
  colonia?: string;
  codigo_postal?: string;
  telefono1?: string;
  telefono2?: string;
  email?: string;
  referencia?: string;
  latitud?: number;
  longitud?: number;
}

export interface CrolClient {
  aniversario?: string;
  apellidoMaterno: string;
  apellidoPaterno: string;
  aplicaCredito?: boolean;
  asentamientoId?: number;
  calle: string;
  ciudad?: string;
  ciudadId?: number;
  codigo?: string;
  codigoPostal: string;
  colonia: string;
  creditoLimite?: number;
  direccion?: string;
  telefono1: string;
  telefono2?: string;
  email?: string;
  entidadId?: number;
  entidadIdPadre?: number;
  entidadTipo?: number;
  modificar?: number;
  nombre: string;
  nombreComercial?: string;
  numeroExterior: string;
  numeroInterior?: string;
  pais?: string;
  paisId?: number;
  estado?: string;
  estadoId?: number;
  puntosSaldo?: number;
  razonSocial?: string;
  razonSocialSin?: string;
  referencia?: string;
  rfc?: string;
  token?: string;
  representateLegal?: string;
  localizaciones?: CrolAddress[];
  domicilioFiscal?: CrolAddress;
  fechaNac?: string;
  generoId?: number;
  esDoctor?: boolean;
  cedula?: string;
  tipoContacto?: string;
  entidadIdPertenece?: number;
  entidadPerteneceNombre?: string;
  regimenId? : string;
  organizacionId?: number;
  c_usoCFDI?: string;
  metodoPagoId?: string;
  formaId ?: string;
}
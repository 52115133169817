import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiURL: string = environment.url;
  azureProductivo: string = (environment.production) ? 'true': 'false';
  
  constructor(
    private http: HttpClient
  ) { }

  get(endpoint: string, params?: any, apiKey?: string) {
    const url = this.apiURL + '/' + endpoint + '?productivo=' + this.azureProductivo + '&ApiKey=' + apiKey;
    return new Promise((resolve, reject) => {  
      let headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      });
      return this.http.get(url, { headers, params }).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  };

  post(endpoint: string, params?: any, apiKey?: string) {
    const url = this.apiURL + '/' + endpoint + '?productivo=' + this.azureProductivo + '&ApiKey=' + apiKey;
    return new Promise((resolve, reject) => {  
      let headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      });
      return this.http.post(url, params, { headers }).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  };

  localServerPing() {
    return new Promise((resolve, reject) => {
      return this.http.get(this.apiURL + '/ping', { observe: 'response' }).pipe(first()).subscribe(resp => {
        if (resp.status === 200 ) {
          resolve(true)
        } else {
          resolve(false)
        }
      }, err => resolve(err))
    })
  }

  getLatestVersion(offline: boolean) {
    const params = new HttpParams().set("offline", offline.toString());
    return this.get('getVersionActual', params);
  }
  

  postTimbrado(token: string, params?: any) {
    //const url = 'https://afcroltimbrado.azurewebsites.net/api/crolTimbrado';
    const url = 'https://cfdi.crol.mx/api/crolTimbrado';
    return new Promise((resolve, reject) => {  
      let headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      });
      return this.http.post(url, params, { headers }).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  };

}

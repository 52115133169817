import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CrolAddress, AutofacturacionService } from './../../../services/autofacturacion/autofacturacion.service';
import { MiscService } from './../../../services/misc/misc.service';

@Component({
  selector: 'app-modal-add-address',
  templateUrl: './modal-add-address.component.html',
  styleUrls: ['./modal-add-address.component.scss'],
})
export class ModalAddAddressComponent implements OnInit {

  showSpinner = false;

  clientId: number;
  contactId: number;
  selectedAddress: CrolAddress = {
    domicilioId: null,
    descripcion: '',
    calle: '',
    noExterior: '',
    noInterior: '',
    colonia: '',
    codigo_postal: ''
  };

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    public _af: AutofacturacionService,
    public _misc: MiscService
  ) {
    if (this.navParams.get('id')) {
      this.clientId = this.navParams.get('id');
    }
    if (this.navParams.get('contactId')) {
      this.contactId = this.navParams.get('contactId');
    }
    if (this.navParams.get('address')) {
      this.selectedAddress = this.navParams.get('address');
      console.log(this.selectedAddress)
    }
  }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss()
  }

  addAddress() {
    this.showSpinner = true;
    if (this.selectedAddress.calle === ''|| this.selectedAddress.noExterior === '' || this.selectedAddress.colonia === '' || this.selectedAddress.codigo_postal === '') {
        this.showSpinner = false;
        this._misc.presentSimpleAlert('Error', '', 'Existen campos obligatorios vacíos');
        return;
      }
    if (this.selectedAddress.domicilioId) {
      // MODIFICAR DOMICILIO
      this._af.editClientAddress(
        this.selectedAddress.domicilioId,
        this.clientId,
        this.contactId,
        this.selectedAddress.calle,
        this.selectedAddress.noExterior,
        this.selectedAddress.noInterior,
        this.selectedAddress.colonia,
        this.selectedAddress.codigo_postal
      ).then(res => {
        this.showSpinner = false;
        console.log(res);
        this.modalCtrl.dismiss({
          'address': this.selectedAddress
        });
      }).catch(error => {
        this.showSpinner = false;
        console.warn(error);
        this._misc.presentSimpleAlert('Error', '', error.error.mensaje)
      })
    } else {
      // AGREGAR DOMICILIO
      this._af.addClientAddress(
        this.clientId,
        this.contactId,
        this.selectedAddress.calle,
        this.selectedAddress.noExterior,
        this.selectedAddress.noInterior,
        this.selectedAddress.colonia,
        this.selectedAddress.codigo_postal
      ).then(res => {
        this.showSpinner = false;
        console.log(res);
        this.modalCtrl.dismiss({
          'address': this.selectedAddress
        });
      }).catch(error => {
        this.showSpinner = false;
        console.warn(error);
        this._misc.presentSimpleAlert('Error', '', error.error.mensaje)
      })
    }
  }

}

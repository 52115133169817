import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule) },
  { path: 'empresa', loadChildren: () => import('./pages/client/client.module').then( m => m.ClientPageModule) },
  { path: 'registro', loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule) },
  { path: 'domicilios', loadChildren: () => import('./pages/address-list/address-list.module').then( m => m.AddressListPageModule) },
  { path: 'exito', loadChildren: () => import('./pages/success/success.module').then( m => m.SuccessPageModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
